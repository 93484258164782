import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResultOf } from '@graphql-typed-document-node/core';
import { CalendarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useOutlet,
  useOutletContext,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from 'urql';
import { Picker } from '~/components/nav/Picker';
import { SiteView } from '~/components/site/SiteView';
import { Dialog } from '~/components/ui/Dialog';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { StatusBadge } from '~/components/ui/StatusBadge';
import {
  JobStatus,
  useAcceptJobMutation,
  useDeclineJobMutation,
  useOfferJobMutation,
  useStartJobMutation,
} from '~/generated/graphql';
import { graphql } from '~/gql';
import { useTitle } from '~/hooks/index';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useLocationHash } from '~/hooks/useLocationHash';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useSite } from '~/routes/sites.$siteId';
import { PopupDialog } from '../../ui/PopupDialog';
import { ConfirmButton } from '../ConfirmDateTime';
import { Accept, Decline, RequestChange } from './Actions';
import { CompleteJobButton } from './CompleteJobButton';
import { Feed } from './Feed';
import { useAddCommentMutation } from './Feed/MessageInput';
import { JobMenu } from './JobMenu';
import { JobReadView } from './JobReadView';

type ContextType = { job: Job; isOutlet: true; onSuccess: () => void };

const JobQueryDocument = graphql(`
  query Job($id: ID!) {
    job(id: $id) {
      id
      status
      jobNumber
      name
      scheduleStart
      scheduleEnd
      scheduledStartStrict
      scheduledStartDate
      scheduledStartTime
      scheduledEndStrict
      scheduledEndDate
      scheduledEndTime
      timeZone
      completedAt
      attributes {
        id
        type
        category
        name
        value
        attachments {
          id
          kind
          ext
          thumb
          url
          originalFilename
        }
      }
      jobTags {
        id
        entityType
        name
        category
        description
        colour
      }
      notes
      attachments {
        id
        kind
        ext
        thumb
        url
        originalFilename
      }
      enforceOrder
      isSource
      permissions
      assignees {
        id
        name
        email
      }
      include {
        id
        name
        email
      }
      owners {
        id
        name
        email
      }
      canAcceptOffer
      customer {
        id
        name
      }
      location {
        __typename
        id
        name
        deployment {
          id
          displayLocation(separator: " / ")
        }
        ... on Site {
          address
        }
      }
      tasks {
        # Some fields are required in JobView to support the mandatory tasks feature
        id
        status
        required
        name
        # type is required for getLinkText in TaskListItem
        type
        # description and notes is required for whether or not to show badge in TaskListItem
        description
        notes
        ...TaskData
      }
      owner {
        id
        name
        logo
      }
      booking {
        id
        name
        start
        end
      }
      occupied {
        id
        checkIn: start
        checkOut: end
      }
      prevCheckOut {
        id
        checkIn: start
        checkOut: end
      }
      nextCheckIn {
        id
        checkIn: start
        checkOut: end
      }
      guestyCleaningStatus
      site {
        id
        image
      }
      occupied {
        id
        checkIn: start
        checkOut: end
      }
      prevCheckOut {
        id
        checkIn: start
        checkOut: end
      }
      nextCheckIn {
        id
        checkIn: start
        checkOut: end
      }
    }
  }
`);

export function useJob(options?: { required?: boolean }) {
  const context = useOutletContext<ContextType | undefined>();

  if (options?.required && !context) {
    throw new Error('Must be used in job outlet context');
  }

  return context;
}

export type Job = NonNullable<ResultOf<typeof JobQueryDocument>['job']>;
export type Task = Job['tasks'][0];

type IndexProps = {
  job: Job;
  basePath?: string;
  onClose: () => void;
};

const Index = ({ job, basePath, onClose }: IndexProps) => {
  // const { t } = useTranslation(['translation', 'job']);

  // const [, completeTask] = useCompleteCustomTaskMutation();
  // const [, reset] = useResetTaskStatusMutation();
  // const { isMobile } = useBreakpoint();
  // const [taskToggleLoading, setTaskToggleLoading] = useState<string>('');

  // const toggleTaskStatus: ToggleTaskStatusFn = async ({ id, checked }) => {
  //   try {
  //     setTaskToggleLoading(id);
  //     const { error } = checked
  //       ? await completeTask({
  //           input: { id, date: new Date() },
  //         })
  //       : await reset({ id });

  //     if (error) {
  //       toast.error(error.message.replace('[GraphQL] ', ''));
  //       throw error;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setTaskToggleLoading('');
  //   }
  // };

  // const GeneratePdfButton = () => {
  //   return (
  //     <button onClick={() => window.print()}>
  //       <FontAwesomeIcon
  //         icon={faFilePdf}
  //         color='#506262'
  //         size='xl'
  //         className='hover:text-brand'
  //       />
  //     </button>
  //   );
  // };

  const [showSite, setShowSite] = useState<boolean>(false);
  const [showFeed, setShowFeed] = useLocationHash('#messages-open');
  // const [isVisible, setIsVisible] = useState(false);
  const [requiredTasks, setRequiredTasks] = useState<Task[]>([]);

  useEffect(() => {
    if (job.tasks.length > 0) {
      setRequiredTasks(
        job.tasks.filter(
          (task) => task.required && task.status === JobStatus.Created
        )
      );
    }
  }, [job]);

  const handleMandatoryTasksSubmit = (event: Event) => {
    if (requiredTasks.length > 0) {
      event.stopImmediatePropagation();

      toast.info(
        `Please complete or defer ${requiredTasks
          .map((task) => task.name)
          .join(', ')} before completing this Job`,
        {
          theme: 'light',
          className: 'bg-[#008F90] text-white',
          icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
        }
      );
    }
  };

  useEffect(() => {
    // If there are incomplete mandatory tasks, this stops propagation of job complete button and show toast message
    const completeBtnEl = document
      .querySelector('#complete-btn-wrapper')
      ?.querySelector('button');

    if (requiredTasks.length < 1 || !completeBtnEl) return;

    completeBtnEl.addEventListener('click', handleMandatoryTasksSubmit);
    return () => {
      completeBtnEl.removeEventListener('click', handleMandatoryTasksSubmit);
    };
  }, [requiredTasks]);

  const site = job.location?.__typename === 'Site' ? job.location : undefined;

  return showFeed ? (
    <Feed job={job} onTabChange={setShowFeed} onClose={onClose} />
  ) : (
    <SideLayout>
      <SideLayout.Head
        rightSlot={
          job.permissions.length > 0 ? (
            <JobMenu job={job} />
          ) : // <GeneratePdfButton />
          null
        }
        // secondRightSlot={
        //   !isMobile && job.permissions.includes('edit') && <GeneratePdfButton />
        // }
        onClose={onClose}
      >
        <div className='flex w-full flex-col items-center'>
          <h5 className='leading-5 text-[#031121]'>
            {job.jobNumber} - {job.name}
          </h5>

          <div className='mb-2 mt-[5px] flex text-base font-medium leading-[17px] text-[#005959]'>
            <span className='text-[#7F909C]'>For</span>
            <span className='ml-[3px]'>{job.owner.name}</span>
            {/* <div
              className='flex'
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => {
                setIsVisible(false);
              }}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className='hover:cursor-pointer'
              />
              <Transition
                className='sticky top-8 z-10 w-full'
                show={isVisible}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-50'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-50 scale-95'
              >
                <AccommodationProvider owner={job.owner} />
              </Transition>
            </div> */}
          </div>

          <StatusBadge value={job.status} />
        </div>
      </SideLayout.Head>

      <PopupDialog isOpen={showSite} onClose={() => setShowSite(false)}>
        <PopupDialog.Title>{site?.name}</PopupDialog.Title>
        {job.location && (
          <SiteView
            isPopup={true}
            siteId={job.location.id}
            onClose={() => setShowSite(false)}
          />
        )}
      </PopupDialog>

      <div className='px-4 py-[2px] print:hidden'>
        <Picker value='details' className='rounded-full'>
          <Picker.Button
            id='details'
            onClick={() => setShowFeed(false)}
            className='rounded-l-full'
          >
            Details
          </Picker.Button>
          <Picker.Button
            id='messages'
            onClick={() => setShowFeed(true)}
            className='rounded-r-full'
          >
            Messages & History
          </Picker.Button>
        </Picker>
      </div>

      <>
        <SideLayout.Body className='relative p-4'>
          <JobReadView job={job} />
        </SideLayout.Body>

        <SideLayout.Foot className='!border-t-0 p-4 text-center print:hidden'>
          {job.status === JobStatus.Complete && (
            <div onClick={onClose} className='cursor-pointer p-3'>
              <div style={{ fontWeight: 500, color: 'var(--color-green)' }}>
                CLOSE
              </div>
            </div>
          )}
          {job.status === JobStatus.InProgress && (
            <div id='complete-btn-wrapper'>
              <CompleteJobButton id={job.id} enabled={true} />
            </div>
          )}
          {job.status === JobStatus.Accepted && <StartJobButton id={job.id} />}
          {job.status === JobStatus.Offered && job.canAcceptOffer && (
            <JobOfferActions job={job} />
          )}
        </SideLayout.Foot>
      </>
    </SideLayout>
  );
};

type Props = {
  jobId: string;
  basePath?: string;
};

/**
 * @todo Finish merge into routes/jobs/$jobId route component
 */
export const JobView = ({ jobId, basePath }: Props) => {
  const matchActivity = useMatch('/activity/*');
  const outlet = useOutlet();
  const matchSites = useMatch('/sites/*');
  const { pathname } = useLocation();
  const siteContext = useSite();

  const [{ fetching, data }, reexecuteQuery] = useQuery({
    query: JobQueryDocument,
    variables: { id: jobId },
    requestPolicy: 'network-only',
  });
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  useTitle(
    data?.job ? [data.job.jobNumber, data.job.name].join(' ') : undefined
  );

  const isPopup =
    pathname.includes('bookings') || pathname.includes('contacts');

  if (fetching)
    return (
      <div className='flex items-center justify-center lg:w-rsb'>
        <Loading spinner />
      </div>
    );

  if (!data?.job)
    return (
      <div className='lg:w-rsb'>
        <ErrorMessage message='Job not found' />
      </div>
    );

  const job = data.job;

  const handleClose = () => {
    // TODO remove state when scroll reset is fixed
    navigate(
      matchActivity || siteContext ? '../..' : '..',
      isMobile ? { state: { jobId: job.id } } : undefined
    );
  };

  const handleTaskSuccess = () => {
    reexecuteQuery({ requestPolicy: 'cache-and-network' });
  };

  return (
    <div
      className={classNames(
        'absolute h-full w-full lg:relative lg:w-auto',
        matchSites ? 'block' : 'flex h-full items-stretch justify-start'
      )}
    >
      <Index job={job} basePath={basePath} onClose={handleClose} />
      {outlet && (
        <>
          {isPopup ? (
            <Outlet
              context={{ job, isOutlet: true, onSuccess: handleTaskSuccess }}
            />
          ) : (
            <div
              className={classNames(
                'absolute h-full w-full min-w-0 flex-1',
                matchSites ? 'inset-0 z-[8999]' : 'z-50 xl:relative'
              )}
            >
              <Outlet
                context={{ job, isOutlet: true, onSuccess: handleTaskSuccess }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const AddToCalendarLink = ({ jobId }: { jobId: number | string }) => {
  const { t } = useTranslation('job');
  return (
    <div className='float-right print:hidden'>
      <a
        href={`/api/calendar/jobs/${jobId}`}
        className='flex items-center text-xs'
        style={{ color: '#757575' }}
      >
        <CalendarIcon className='mr-2 h-6 w-6' aria-hidden='true' />
        {t('addToCalendar')}
      </a>
    </div>
  );
};

const SendJobButton = ({ job }: { job: Job }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [result, send] = useOfferJobMutation();
  const { fetching } = result;

  const confirm = async () => {
    setShowDialog(false);
    await send({ id: job.id, comment: inputRef.current?.value });
  };

  if (![JobStatus.Created, JobStatus.Offered].includes(job.status)) {
    return null;
  }

  return (
    <>
      <Dialog
        title='Almost there!'
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <div className='flex flex-col flex-wrap gap-3 px-5'>
          <p className='px-2 pt-3'>
            You are about to send the job{' '}
            <span className='font-semibold'>{job.name}</span> to{' '}
            <span className='font-semibold'>
              {job.assignees?.map(({ name }) => name).join(', ')}
            </span>
          </p>
          <textarea
            ref={inputRef}
            className='input mt-1 w-full resize-none rounded-md border border-grey-20 px-3 py-2.5 focus-visible:border-brand focus-visible:outline-none'
            placeholder='Add optional message...'
            autoFocus
          ></textarea>
          <div className='flex gap-3 self-end pb-5 pt-1'>
            <button
              className='px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand'
              onClick={() => setShowDialog(false)}
            >
              Cancel
            </button>
            <button
              type='button'
              className='rounded-md border border-brand bg-brand px-5 py-2 text-sm font-medium uppercase text-white transition-all duration-200 hover:bg-brand-dark hover:transition-all hover:duration-500'
              onClick={confirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>
      <div className='float-right'>
        <button
          className='rounded-lg border border-firefly px-3 py-1 text-sm leading-tight text-firefly'
          onClick={() => setShowDialog(true)}
          disabled={fetching}
        >
          {fetching
            ? 'Sending...'
            : job.status === JobStatus.Created
            ? 'Send'
            : 'Resend'}
        </button>
      </div>
    </>
  );
};

export function StartJobButton({ id }: { id: string }) {
  const { t } = useTranslation('job');
  const [, start] = useStartJobMutation();

  return (
    <ConfirmButton
      title='StartTime'
      onConfirm={async (date) => {
        await start({ id, startTime: date });
      }}
    >
      {t('startJob')}
    </ConfirmButton>
  );
}

function JobOfferActions({ job }: { job: Job }) {
  const [, accept] = useAcceptJobMutation();
  const [, decline] = useDeclineJobMutation();
  const [, addComment] = useAddCommentMutation();

  return (
    <>
      <div className='-mb-2 flex gap-4 pt-1'>
        <Accept jobId={job.id} jobName={job.name} onAccept={accept} />
        <Decline jobId={job.id} jobName={job.name} onDecline={decline} />
        <span />
      </div>
      <RequestChange jobId={job.id} onSubmit={addComment} />
    </>
  );
}
